@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: GlossySheenRegular;
    src: url("./fonts/Glossy Sheen Regular DEMO.ttf");
}

/*@font-face {*/
/*  font-family: GlossySheenShadow;*/
/*  src: url("./fonts/Glossy Sheen Shadow DEMO.ttf");*/
/*}*/

/*@font-face {*/
/*  font-family: GlossySheenShine;*/
/*  src: url("./fonts/Glossy Sheen Shine DEMO.ttf");*/
/*}*/

/*@font-face {*/
/*  font-family: GlossySheenOutline;*/
/*  src: url("./fonts/Glossy Sheen Outline DEMO.ttf");*/
/*}*/

.App {
    margin: 0 auto;
}

.contentWrapper {
    margin: 0 auto;
    max-width: 1077px;
}

body > iframe {
    display: none;
}

body {
    margin: 0;
    background-color: #57b3d0;
    font-family: 'Roboto', 'Oxygen', 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 400;
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 1vh;
    line-height: 1.4375rem;
    color: #070600;
    overflow-wrap: break-word;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section {
    margin: 1rem;
    max-width: 100%;
    overflow: hidden;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

h1 {
    font-family: GlossySheenRegular;
    font-weight: 400;
    font-size: 7.5rem;
    line-height: 6.25rem;
    color: #FD921B;
    text-shadow: 7px 7px #070600;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

h2 {
    font-family: GlossySheenRegular;
    font-weight: 400;
    font-size: 5.75rem;
    line-height: 4.5rem;
    color: #EE1B1B;
    text-shadow: 6px 6px #070600;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

h3 {
    font-family: GlossySheenRegular;
    font-weight: 100;
    font-size: 3.5rem;
    line-height: 4rem;
    color: #070600;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
    h1 {
        font-size: 5.5rem;
        line-height: 4.25rem;
        text-shadow: 4px 4px #070600;
    }
    .section {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
    }
}

@media only screen and (max-width: 500px) {
    h1 {
        font-size: 4.15rem;
        line-height: 3rem;
        text-shadow: 3px 3px #070600;
    }
}